import { useState } from "react"
import ReactMarkdown from "react-markdown"
import styled from "styled-components"

import { Skeleton } from "@material-ui/lab"

import {
  HomeGroupsDropdown,
  IHomeGroupDropdownProps,
} from "src/components/Dropdown/HomeGroupsDropdown"
import {
  CREATE_HOME_FORM_ID,
  EMPTY_AUTOCOMPLETE_ADDRESS,
} from "src/components/Homes/CreateHomeWizard/createHomeConstants"
import { GooglePlacesAutoComplete } from "src/components/Homes/GooglePlacesAutoComplete"
import { extractAddressData } from "src/data/homeAddress/logic/homeAddressDataUtils"
import { useFetchHomeGroups } from "src/data/homeGroups/queries/homeGroupQueries"
import {
  HomeGroupRole,
  TMaybeHomeGroup,
} from "src/data/homeGroups/types/homeGroupTypes"
import { IAddress } from "src/data/homes/types/homeTypes"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { useTranslate } from "src/i18n/useTranslate"
import { TextButton } from "src/ui/Button/TextButton"
import { colorsV2 } from "src/ui/colors"
import InfoIcon from "src/ui/icons/info.svg"
import { MBanner } from "src/ui/MBanner/MBanner"
import { MText } from "src/ui/MText"
import { MTextField } from "src/ui/MTextField/MTextField"
import { spacing } from "src/ui/spacing"

export function HomeInformationStep({
  homeName: initialHomeName,
  addressSearchString: initialAddressString,
  autoCompleteAddress: initialAutoCompleteAddress,
  loading,
  error,
  setError,
  onSubmit,
}: {
  homeName: string
  addressSearchString: string
  loading: boolean
  autoCompleteAddress: IAddress | undefined
  error: boolean
  setError: (value: boolean) => void
  onSubmit: (
    homeName: string,
    homeGroup: TMaybeHomeGroup,
    address: { completedAddress: IAddress; searchString: string }
  ) => void
}) {
  const { t, langKeys } = useTranslate()
  const { org } = useOrganization()

  const [autoCompleteAddress, setAutoCompleteAddress] = useState<IAddress>(
    EMPTY_AUTOCOMPLETE_ADDRESS
  )
  const [homeGroup, setHomeGroup] = useState<TMaybeHomeGroup>(null)

  const homeGroupProps: IHomeGroupDropdownProps = {
    onSelect: setHomeGroup,
    value: homeGroup,
    baseFilter: { role: HomeGroupRole.ADMIN },
  }

  const [homeName, setHomeName] = useState(initialHomeName)
  const [addressSearchString, setAddressSearchString] =
    useState(initialAddressString)

  const [useManualAddress, setUseManualAddress] = useState(false)

  const fetchHomeGroups = useFetchHomeGroups({
    orgId: org.id,
    filter: { limit: 1 }, // We don't care about which homegroups, just that any exists
  })

  const isLoading = loading || fetchHomeGroups.isLoading

  function getAddress({
    address,
    addressComponents,
  }: {
    address: string
    addressComponents: google.maps.GeocoderAddressComponent[] | null
  }) {
    if (!addressComponents) {
      setAddressSearchString("")
      setAutoCompleteAddress(extractAddressData([]))
      return
    }
    setAddressSearchString(address)
    setAutoCompleteAddress(extractAddressData(addressComponents))
  }

  if (isLoading) {
    return <InformationStepSkeleton />
  }

  if (error || fetchHomeGroups.isError) {
    setError(true)

    return (
      <Container>
        <Title organizationName={org.name} />

        <MBanner type="error" fullWidth>
          {t(langKeys.failed_something_went_wrong)}
        </MBanner>
      </Container>
    )
  }

  return (
    <Container>
      <Title organizationName={org.name} />

      <HomeLimitBanner
        show={
          org.billing_model === "contract" &&
          !!org.subscription_quantity_limit_reached
        }
      />

      <FormBox
        id={CREATE_HOME_FORM_ID}
        onSubmit={(e) => {
          e.preventDefault()
          onSubmit(homeName, homeGroup, {
            completedAddress: autoCompleteAddress,
            searchString: addressSearchString,
          })
        }}
      >
        <MTextField
          label={t(langKeys.home_name)}
          autoComplete="off"
          required
          value={homeName}
          onChange={(value) => setHomeName(value)}
          onBlur={(e) => setHomeName(e.target.value.trim())}
        />

        {fetchHomeGroups.data?.paging.total_count > 0 && (
          <HomeGroupsDropdown {...homeGroupProps} />
        )}

        <AutocompleteGrid>
          <GooglePlacesAutoComplete
            currentLocation={addressSearchString}
            onLocationSelected={getAddress}
            label={t(langKeys.address)}
            required={!useManualAddress}
          />
          <div>
            <TextButton
              type="submit"
              form={CREATE_HOME_FORM_ID}
              onClick={() => {
                setUseManualAddress(true)
                setAddressSearchString("")
                setAutoCompleteAddress(EMPTY_AUTOCOMPLETE_ADDRESS)
              }}
            >
              {t(langKeys.create_home_enter_address_manually)}
            </TextButton>
          </div>
        </AutocompleteGrid>
      </FormBox>
    </Container>
  )
}

function Title({ organizationName }: { organizationName: string }) {
  const { t, langKeys } = useTranslate()

  return (
    <MText variant="heading2">
      {t(langKeys.create_home_information_step_title, {
        organization: organizationName,
      })}
    </MText>
  )
}

function InformationStepSkeleton() {
  return (
    <FormBox>
      <Skeleton variant="rect" />
      <Skeleton variant="rect" />
      <Skeleton variant="rect" />
    </FormBox>
  )
}

function HomeLimitBanner({ show }: { show: boolean }) {
  const { t, langKeys } = useTranslate()

  if (!show) {
    return null
  }

  return (
    <MBanner
      title={t(langKeys.create_home_contract_limit_banner_title)}
      type="error"
      icon={<InfoIcon width={20} color={colorsV2.systemErrorDark} />}
    >
      <MText color="emergency">
        <ReactMarkdown>
          {t(langKeys.create_home_contract_limit_banner_body)}
        </ReactMarkdown>
      </MText>
    </MBanner>
  )
}

const Container = styled.div`
  display: grid;
  gap: ${spacing.XL};
`

const FormBox = styled.form`
  display: grid;
  gap: ${spacing.L};
`

const AutocompleteGrid = styled.div`
  display: grid;
  gap: ${spacing.S};
`
